export function dateTimeHelpers(dateTimeString) {
  const [datePart, timePart] = dateTimeString.split(', ');
  const [day, month, year] = datePart.split('.');

  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')} ${timePart}`;
}

export function getJustDateStringFromDate(date) {
  return date.toISOString().substr(0, 10);
}

export function getJustTimeStringFromDate(date) {
  return date.toISOString().substr(11, 5);
}

export function createTwoDigitNumberFromNumber(number) {
  return number.toString().padStart(2, '0');
}

export function createDateCurrentPlusThreeDays() {
  return new Date(Date.now() + 3 * 60 * 60 * 1000 - 5 * 60 * 1000);
}

export function createDateCurrentMinusFourDays() {
  return new Date(Date.now() - 4 * 24 * 60 * 60 * 1000);
}

export function createDateCurrentMinusOneMinute() {
  return new Date(Date.now() - 1 * 60 * 1000);
}

export function createDateCurrentWithoutTimezoneOffset() {
  return new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000);
}

export function getHoursAndMinutesFromString(time) {
  const tupleTime = time.split(':').map(Number);
  return tupleTime;
}

export const regexFormatDate = /^\d{2}\.\d{2}\.\d{4}, \d{2}:\d{2}:\d{2}$/;

export function createISODateString(dateString) {
  return new Date(dateString.split(', ')[0].split('.').reverse().join('-') + 'T' + dateString.split(', ')[1] + 'Z').toISOString();
}

export const ruLocaleDateString = 'ru-RU';

export const localeDateOptions = {hour12: false};
